import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import { Error, Popup, Select, Button } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import { injectFormSnackbar } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { destroy, Field, formValueSelector, isPristine, reduxForm, SubmissionError } from 'redux-form'
import * as donneesExamenSelectors from '../../donneesExamen/donneesExamenSelectors'
import * as examensActions from '../services/examensActions'
import * as examensSelectors from '../services/examensSelectors'

const styles = (theme) => getStyles({
	popupForm: {
		marginTop: 20
	},
	sanctionMessage: { // Message s'affichant à la place du formulaire en cas de sanction encore active
		marginTop: 25,
		marginBottom: 25,
		color: theme.palette.warning
	},
	error: { // Message suite à une erreur asynchrone
		marginTop: 10
	}
})

const validate = (values, { intl }) => {
	const errors = {}

	if (!values.categorie) {
		errors.categorie = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
	}
	if (!values.typeExamen) {
		errors.typeExamen = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
	}

	return errors
}

class NouvelExamenPopup extends Component {
	submitExamen = (values) => {
		const { postExamen, redirectExamen } = this.props

		return postExamen(values)
			.then((examenId) => {
				this.closePopup()
				redirectExamen(examenId)
			})
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	/**
	 * N'affiche le formulaire de création que si l'on est autorisé à créer un examen pour le candidat
	 */
	popupContent = () => {
		const {
			intl,
			typesExamen,
			categories,
			error
		} = this.props

		return <form>
			<Error error={error} isPopinContainer />
			<Field
				id="categorie"
				name="categorie"
				label={intl.formatMessage({ id: 'examens.nouveau.categorie' })}
				component={Select}
			>
				{categories.map(categorie =>
					<MenuItem key={categorie.id} value={categorie.id}>
						{categorie.libelle}
					</MenuItem>
				)}
			</Field>

			{typesExamen &&
			<Field
				id="typeExamen"
				name="typeExamen"
				label={intl.formatMessage({ id: 'examens.nouveau.examenType' })}
				component={Select}
			>
				{typesExamen.map(typeExamen =>
					<MenuItem key={typeExamen.id} value={typeExamen.id}>
						{typeExamen.libelle}
					</MenuItem>
				)}
			</Field>
			}
		</form>
	}

	closePopup = () => {
		const { closePopup, clearForm } = this.props
		clearForm()
		closePopup()
	}

	render() {
		const {
			open,
			intl,
			classes,
			handleSubmit,
			submitting
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={this.closePopup}>
				<FormattedMessage id="global.btn.annuler" />
			</Button>,
			<Button
				key="save"
				color="primary"
				variant="contained"
				loading={submitting}
				onClick={handleSubmit(this.submitExamen)}
			>
				<FormattedMessage id='global.btn.creer' />
			</Button>
		]

		return (
			<div className={classes.popupForm}>
				<Popup
					open={open}
					actions={actions}
					title={intl.formatMessage({ id: 'examens.nouveau.titre' })}
					content={this.popupContent()}
					onExited={this.closePopup}
				/>
			</div>
		)
	}
}

NouvelExamenPopup.propTypes = {
	classes: PropTypes.object,
	categories: PropTypes.array,
	typesExamen: PropTypes.array,
	open: PropTypes.bool,
	submitting: PropTypes.bool,
	closePopup: PropTypes.func.isRequired,
	clearForm: PropTypes.func.isRequired,
	postExamen: PropTypes.func.isRequired,
	redirectExamen: PropTypes.func.isRequired
}

const NOUVEL_EXAMEN_FORM_NAME = 'nouvelExamenForm'
const getFormValues = formValueSelector(NOUVEL_EXAMEN_FORM_NAME)

const mapStateToProps = state => ({
	open: examensSelectors.isOpened(state),
	categories: donneesExamenSelectors.getCategories(state),
	typesExamen: donneesExamenSelectors.getTypesExamenParCategorie(state)[getFormValues(state, 'categorie')],
	initialValues: {
		categorie: 0,
		typeExamen: 0
	},
	submitting: isPristine(NOUVEL_EXAMEN_FORM_NAME)(state)
})

const actions = {
	closePopup: examensActions.closePopup,
	clearForm: () => dispatch => dispatch(destroy(NOUVEL_EXAMEN_FORM_NAME)),
	postExamen: examensActions.postExamen,
	redirectExamen: (id) => dispatch => dispatch(push(`/examens/${id}`))
}

export default compose(
	withStyles(styles),
	injectIntl,
	connect(mapStateToProps, actions),
	reduxForm({
		form: NOUVEL_EXAMEN_FORM_NAME,
		validate,
		enableReinitialize: true
	}),
	injectFormSnackbar()
)(NouvelExamenPopup)
